
*, *:after, *:before { -webkit-box-sizing: border-box; box-sizing: border-box; }
.clearfix:before, .clearfix:after { content: ''; display: table; }
.clearfix:after { clear: both; }

body {
  background-color: #ff7f00;
  font-family: 'Raleway', Arial, sans-serif;
  overflow: hidden;
}

.App-header {
  position: absolute;
  top: 0;
  right: 0;
  padding: 40px;
  z-index: 9999;
}
.App-header h1{
  color: #fff;
  font-weight: 100;
  font-size: 4.5em;
  line-height: 0.75;
  letter-spacing: -1px;
  margin: 0;
  cursor: pointer;
}

.App-link {
  color: rgb(112, 76, 182);
}

.anim {
  height: 100vh;
}

.voice {
  position: absolute;
  bottom: 40%;
  left: 250px;
  font-size: 48px;
  color: #fff;
}
.voice .typed-cursor {
  display: none;
}

@media screen and (max-width: 53.125em) {
  .voice {
    left: 20px;
  }
}